<template>
  <v-container id="regular-tables" fluid tag="section">
    <base-material-card
      icon="mdi-help-circle"
      title="Consultation Drug Delivery"
      class="px-5 py-3">
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="params.phone"
            label="Search by phone number"
            single-line
            v-on:keyup.enter="getConsultationDrugs(1)"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-progress-linear
        v-if="loading"
        indeterminate
        color="blue"
      ></v-progress-linear>
      <v-simple-table>
        <thead>
        <tr>
          <th class="primary--text">
            First Name
          </th>
          <th class="primary--text">
            Phone Number
          </th>
          <th class="primary--text">
            Gender
          </th>
          <th class="primary--text">
            Date of Birth
          </th>
          <th class="primary--text">
            Doctor
          </th>
          <th class="primary--text">
            Branch Assigned
          </th>
          <th class="primary--text">
            Dispense Status
          </th>
          <th class="primary--text">
            Created At
          </th>
          <th class="text-center primary--text">
            Options
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(consultation_drug, index) in consultation_drugs">
          <td>
            {{ getPhr(consultation_drug).name }}
          </td>
          <td>
            {{ getPhr(consultation_drug).phone }}
          </td>
          <td>
            {{ getPhr(consultation_drug).gender }}
          </td>
          <td>
            {{ getPhr(consultation_drug).date_of_birth }}
          </td>
          <td>
            {{ consultation_drug.location ? consultation_drug.location.name_en ? consultation_drug.location.name_en : consultation_drug.location.name_ar : '' }}
          </td>
          <td>
            <template v-if="consultation_drug.branch_name">
              {{ consultation_drug.branch_name }}
            </template>
            <template v-else>
              Not Assigned
            </template>
          </td>
          <td>
            <template v-if="consultation_drug.recommendationFlag && consultation_drug.recommendationFlag.dispense_status">
              {{ consultation_drug.recommendationFlag.dispense_status | snakeToUpper }}
            </template>
            <template v-else>
              Pending
            </template>
          </td>
          <td>
            {{ consultation_drug.date_added }}
          </td>
          <td class="text-center">
            <div class="d-inline-flex">
              <v-btn x-small fab color="info" class="mr-2" :to="{ path: '/consultation-drug-delivery/'+ consultation_drug.consultation_id+'/dispense'}">
                <v-icon>mdi-eye</v-icon>
              </v-btn>
              <v-btn x-small class="ml-2" fab :href="consultation_drug.prescriptionPdfUrl" download :disabled="!consultation_drug.prescriptionPdfUrl">
                <v-icon>mdi-download</v-icon>
              </v-btn>
              <v-dialog
                v-model="branchDialogs[index].show"
                scrollable
                max-width="600px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :disabled="consultation_drug.branch_id && $auth.getCurrentUser().branch_id !== consultation_drug.branch_id"
                    x-small fab
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    class="ml-2"
                    @click="setBranch(consultation_drug)"
                  >
                    <v-icon>mdi-source-branch-plus</v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>Select Branch to Assign</v-card-title>
                  <v-divider></v-divider>
                  <v-card-text style="height: 300px;">
                    <VValidationErrors :validationErrors="branchAssignValidationErrors"></VValidationErrors>
                    <v-autocomplete
                      v-model="branch_id"
                      :items="branches"
                      item-text="name"
                      item-value="id"
                      dense
                      label="Select branch to assign"
                    ></v-autocomplete>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="branchDialogs[index].show = false"
                    >
                      Close
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="assignToBranch(consultation_drug.consultation_id, index)"
                    >
                      Assign
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </td>
        </tr>
        </tbody>
      </v-simple-table>
    </base-material-card>
    <div class="text-center">
      <v-pagination
        v-model="params.page"
        :length="pagination.length"
        :total-visible="7"
        @input="getConsultationDrugs"
        @next="getConsultationDrugs"
        @previous="getConsultationDrugs"
      />
    </div>
    <div class="py-3"/>
  </v-container>
</template>

<script>
import { VueTelInput } from 'vue-tel-input'
import VValidationErrors from "../../components/base/VValidationErrors";

export default {
  name: 'Consultation Drug Delivery',
  components: {
    VValidationErrors,
    VueTelInput,
  },
  created () {
    this.getConsultationDrugs();
    this.getBranches();
  },
  data () {
    return {
      menu_date_from: false,
      reportData: {
        date_from: '',
        date_to: '',
      },
      menu_date_to: false,
      loading: false,
      countryCode: '',
      mobile: '',
      params: {
        phone: '',
        page: 1,
      },
      pagination: {
        length: 0,
        total: 0
      },
      consultation_drugs: [],
      validationErrors: {},
      branchAssignValidationErrors: {},
      branch_id: '',
      branchDialogs: [],
      branches: [],
      branch_pagination: {
        page: 1,
        length: 0,
        total: 0
      },
    }
  },
  methods: {
    getConsultationDrugs (page = false) {
      if (page) {
        this.params.page = page;
      }
      this.loading = true;
      this.$authApi.get('consultations/consultation-drugs', {
        params: this.params
      }).then(res => {
        this.loading = false;
        this.consultation_drugs = res.data.data.consultations.items
        this.pagination.length = res.data.data.consultations.extra._meta.pageCount;
        let _this = this;
        this.consultation_drugs.forEach(function (drug) {
          _this.branchDialogs.push({
            show: false
          });
        });
      }).catch(err => {
        this.loading = false;
      })
    },
    getPhr(consultation) {
      return consultation.phr ? consultation.phr : consultation.member.myPhr
    },
    getBranches() {
      this.$authApi.get(`/branches`, {
        params: {'per_page': '*'}
      }).then(res => {
        this.branches = res.data.data.data;
        this.branch_pagination.length = res.data.data.last_page;
        this.branch_pagination.total = res.data.data.total;
      })
    },
    assignToBranch(consultation_id, index) {
      this.$authApi.post(`consultations/${consultation_id}/assign-to-branch`, {branch_id: this.branch_id}).then(res => {
        this.branchAssignValidationErrors = {};
        this.branchDialogs[index].show = false;
        this.flashMessage.success({
          title: 'Success!',
          message: `Prescription assigned to branch successfully`,
          position: 'right top'
        });
        this.getConsultationDrugs();
      }).catch(error => {
        if (error.response) {
          this.branchAssignValidationErrors = error.response.data.errors;
        }
      })
    },
    setBranch(consultation) {
      this.branch_id = consultation.branch_id ? consultation.branch_id : null;
    }
  },
}
</script>

